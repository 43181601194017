import { Content } from "../_services/Content";
import { getBackendUrlPrefix } from "../config";
import defaultAvatar from "../_assets/images/Avatar.png";

const Helper = {
  testPassword(password: string) {
    return new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/).test(
      password
    );
  },

  testPhone(phone: string) {
    return new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/).test(
      phone
    );
  },

  testEmail(email: string) {
    return new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/).test(
      email
    );
  },

  testNotEmpty(text: string) {
    return new RegExp(/^(?!\s*$).+/).test(text);
  },

  getToken() {
    return localStorage.getItem("userAuthToken") || undefined;
  },

  setToken(token: string | undefined) {
    if (token) {
      localStorage.setItem("userAuthToken", token);
    } else {
      localStorage.removeItem("userAuthToken");
    }
  },

  formatFirstname(text: string) {
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
  },

  setFreelancerAvatar(id: string) {
    if (Content("SHOW_FREELANCER_INFORMATIONS")) {
      return `url(${getBackendUrlPrefix()}/select/v2/freelancer/picture/${id})`;
    } else {
      return `url(${defaultAvatar})`;
    }
  },
};

export default Helper;
