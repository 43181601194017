import React from "react";
import "./App.scss";
import useAuthenticate from "./_services/Auth";
import UserRouter from "./_pages/Router";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { User } from "./_models/User";
import ResetPassword from "./_pages/PasswordAuth/ResetPassword";
import ForgotPassword from "./_pages/PasswordAuth/ForgotPassword";
import Home from "./_pages/Home";

export const UserContext = React.createContext<{
  user?: User;
  refreshToken: (token?: string) => void;
  refreshUser: () => void;
}>({
  user: undefined,
  refreshToken: () => undefined,
  refreshUser: () => undefined,
});

const App = () => {
  const { isLoadingUser, isLogged, user, refreshToken, refreshUser } =
    useAuthenticate();

  return (
    <Router>
      <Route
        path="*"
        component={() => {
          window.location.href = "https://select.client.cremedelacreme.io";
          return null;
        }}
      />
    </Router>
  );
  // return (
  //   <div>
  //     {!isLoadingUser &&
  //       (isLogged && user ? (
  //         <UserContext.Provider value={{ user, refreshToken, refreshUser }}>
  //           <UserRouter />
  //         </UserContext.Provider>
  //       ) : (
  //         <Router>
  //           <Switch>
  //             <Route
  //               path="/reset-password"
  //               component={() => <ResetPassword refreshToken={refreshToken} />}
  //             ></Route>
  //             <Route
  //               path="/forgot-password"
  //               component={() => <ForgotPassword />}
  //             ></Route>
  //             {/* <Route
  //               path="/"
  //               exact
  //               component={() => <Login refreshToken={refreshToken} />}
  //             /> */}
  //             <Route
  //               path="/"
  //               exact
  //               component={() => <Home refreshToken={refreshToken} />}
  //             />
  //             <Redirect to="/" />
  //           </Switch>
  //         </Router>
  //       ))}
  //   </div>
  // );
};

export default App;
