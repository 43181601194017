import logo_full_creme from "../_assets/images/Logo_Full_White.png";
import laposte from "../_assets/domain_logo/groupelaposte.png";
import laposte_black from "../_assets/domain_logo/groupelaposte_black.png";
import accenture from "../_assets/domain_logo/accenture.png";
import accenture_black from "../_assets/domain_logo/accenture_black.png";
const laposte_video = require("../_assets/domain_logo/groupelaposte.mp4");
const accenture_video = require("../_assets/domain_logo/accenture.mp4");

const list: { [key: string]: any } = {
  COMPANY_NAME: {
    default: "",
    groupelaposte: "Groupe La Poste",
    accenture: "Accenture",
  },
  CTA_PROJECT_SUBMISSION: {
    accenture: "Déposer un brief",
    groupelaposte: "Déposer un brief",
    default: "Trouver un freelance",
  },
  SHOW_HEADER_LINKS: {
    groupelaposte: false,
    accenture: false,
    default: true,
  },
  HOME_SUBTITLE: {
    accenture:
      '<div style="max-width: 560px">Les équipes de crème de la crème sélectionnent pour Accenture le top 10% des meilleurs experts freelances.</div>',
    groupelaposte:
      '<div style="max-width: 560px">Les équipes de crème de la crème sélectionnent pour le groupe La Poste le top 10% des meilleurs experts freelances.</div>',
    default:
      "Recevez un mail chaque semaine, avec les meilleurs profils disponibles, gratuitement.",
  },
  HOME_TITLE: {
    default: "<h1>Select.</h1>",
    groupelaposte: `<img class="logo-company" src=${laposte} alt="groupelaposte" /><div class="logo-mini"> avec <img src=${logo_full_creme} alt="logo_creme" /></div>`,
    accenture: `<img class="logo-company" src=${accenture} alt="groupelaposte" /><div class="logo-mini"> avec <img src=${logo_full_creme} alt="logo_creme" /></div>`,
  },
  HOME_DESCRIPTION_LIST: {
    default:
      "<ul><li>Une sélection régulière de 8 top freelances</li><li>Des profils certifiés et disponibles immédiatement</li><li>Une personnalisation selon vos enjeux récurrents</li></ul>",
    groupelaposte:
      "<ul><li>Des experts certifiés par nos soins et disponibles immédiatement.</li><li>Les dernières compétences tech, data, project management, UX/UI et digital marketing.</li><li>Une équipe dédiée pour vous accompagner à chaque instant.</li></ul>",
    accenture:
      "<ul><li>Des experts certifiés par nos soins et disponibles immédiatement.</li><li>Les dernières compétences tech, data, project management, UX/UI et digital marketing.</li><li>Une équipe dédiée pour vous accompagner à chaque instant.</li></ul>",
  },
  HOME_VIDEO: {
    default: '<div class="square"></div>',
    groupelaposte: `<video loop muted><source src=${laposte_video} type="video/mp4" /></video>`,
    accenture: `<video loop muted><source src=${accenture_video} type="video/mp4" /></video>`,
  },
  VALIDATION_SIGNUP: {
    default:
      '<div class="signup-explanation">Votre demande est validée, nous vous contacterons dans les plus brefs délais pour valider votre inscription.</div><br /><div class="signup-explanation">Une fois validée, vous pourrez paramétrer votre sélection : vous recevrez alors régulièrement les profils des freelances disponibles que vous recherchez.</div>',
    groupelaposte:
      '<div class="signup-explanation">Retrouvez le lien de connexion dans vos emails :)</div><br /><div class="signup-explanation">Vous pourrez alors paramétrer votre compte et recevoir régulièrement les profils qui vous interessent.</div><br /><div class="signup-explanation">Pour soumettre un brief de mission, cliquez simplement sur <b>Déposer un brief</b>.</div>',
  },
  DASHBOARD_SHOW_BRIEF_CTA: {
    default: false,
    groupelaposte: true,
    accenture: true,
  },
  DASHBOARD_HEADER_LOGO: {
    default: "",
    groupelaposte: `<img class="logo-company" src=${laposte_black} alt="groupelaposte" />`,
    accenture: `<img class="logo-company" src=${accenture_black} alt="groupelaposte" />`,
  },
  SHOW_FREELANCER_INFORMATIONS: {
    default: true,
    accenture: false,
  },
};

export function Content(key: string): any {
  const hostname = window.location.hostname.replace(".cremedelacreme.io", "");

  if (list[key][hostname] !== undefined) {
    return list[key][hostname];
  }
  return list[key]["default"];
}
