export function getBackendUrlPrefix(): string {
  if (
    window.location.hostname.includes(".cremedelacreme.io") ||
    window.location.hostname.includes(
      "poc-creme-freelance-staging.herokuapp.com"
    )
  ) {
    return "https://creme-growth.herokuapp.com";
  }

  return "http://localhost:8000";
}
