import { useState, useEffect } from "react";
import { User } from "../_models/User";
import { getBackendUrlPrefix } from "../config";
import Helper from "../_helpers/Helpers";

const Window: any = window;

interface AuthenticateHook {
  isLoadingUser: boolean;
  isLogged: boolean;
  authToken?: string;
  user?: User;
  logout: () => void;
  refreshToken: (token?: string) => void;
  refreshUser: () => void;
}

const useAuthenticate = (): AuthenticateHook => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [authToken, setAuthToken] = useState<string | undefined>(
    Helper.getToken()
  );
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  useEffect(() => {
    const setUserTokenIfLogged = async (): Promise<void> => {
      await refreshUser();
    };

    setUserTokenIfLogged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const refreshUser = async () => {
    let loggedUserToken = Helper.getToken();

    if (!loggedUserToken) {
      const newToken = await checkInviteToken();
      Helper.setToken(newToken);
      setAuthToken(newToken);

      if (authToken === newToken) {
        setIsLoadingUser(false);
      }
    } else {
      const userResponse = await getUser(loggedUserToken);

      if (userResponse) {
        setAuthToken(loggedUserToken);
        setUser(userResponse);

        Window.analytics.identify(userResponse.id, {
          email: userResponse.email,
        });

        Window.$crisp.push(["set", "user:email", userResponse.email]);
        Window.$crisp.push([
          "set",
          "user:nickname",
          `${userResponse.firstname} ${userResponse.lastname}`,
        ]);
      } else {
        Window.analytics.reset();
        setUser(undefined);
        Helper.setToken(undefined);
      }

      setIsLoadingUser(false);
    }
  };

  const refreshToken = (token?: string) => {
    if (!token) {
      logout();
    } else {
      Helper.setToken(token);
      setAuthToken(token);
    }
  };

  const logout = () => {
    Helper.setToken(undefined);
    setAuthToken(undefined);
    setUser(undefined);
  };

  return {
    isLoadingUser,
    isLogged: !!user,
    authToken,
    user,
    logout,
    refreshToken,
    refreshUser,
  };
};

async function getUser(token: string): Promise<User | undefined> {
  try {
    const result = await fetch(`${getBackendUrlPrefix()}/select/v2/user`, {
      method: "GET",
      headers: {
        "x-access-token": token,
      },
    });
    if (result.status === 200) {
      const user = await result.json();
      return user;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}

async function checkInviteToken(): Promise<string | undefined> {
  const params = new URLSearchParams(Window.location.search);
  const inviteToken = params.get("inviteToken");

  if (inviteToken) {
    try {
      const result = await fetch(
        `${getBackendUrlPrefix()}/select/v2/login/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ inviteToken }),
        }
      );

      const { token } = await result.json();

      if (result.ok) {
        Window.analytics.track("SELECT_login_via_token_success");
        return token;
      } else {
        Window.analytics.track("SELECT_login_via_token_fail");
        return undefined;
      }
    } catch (e) {
      Window.analytics.track("SELECT_login_via_token_fail");
      return undefined;
    }
  } else {
    return undefined;
  }
}

export default useAuthenticate;
